<template>
    <div class="site-login w-100 p-xxl-5 p-xl-5 p-lg-5 p-md-3 p-sm-2 p-2">
        <h2 class="text-purple mb-3">{{ translations.create_an_account }}</h2>
        <signup-form :translations="translations"></signup-form>
    </div>
</template>

<script>
    import SignupForm from "./SignupForm.vue";

    export default {
        name: "guest-signup",

        components: {
            SignupForm
        },
        computed: {
            translations() {
                return this.$store.state.translations;  
            },
        },
    };
</script>
